body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}

.MuiStepConnector-horizontal {
  width: 60px;
  margin-left: -20px;
  top: 30px !important;
  z-index: -1;
}

.MuiStep-horizontal {
  height: 64px !important;
  svg {
    width: 54px;
    height: 64px;
  }
}

.MuiButtonBase-root.Mui-disabled {
  background-color: #cecece !important;

}

.MuiStepConnector-line {

  margin-left: 5px;
}

.center-div {
  max-width: 800px;
  padding: 10px;
  margin: 0 auto;
}

.action-button {
  cursor: pointer;
}

.action-buttons svg {
  padding: 0 10px;
  cursor: pointer;
}

.action-buttons {
  cursor: pointer;
  display: inline-flex;
  vertical-align: revert;
}

.topbar {
  background-color: #000 !important;
}

.navbar {
  background-color: #021633 !important;
}

.progress-container {
  height: 30px;
  width: 100%;
  position: relative;
  border: 1px solid #939393;
  background-color: #939393;
}

.table-progress {
  height: 28px;
  background-color: #71BD33;
}

.progress-container .label {
  color: #fff;
  height: 30px;
  width: 100%;
  top: -30px;
  margin: 0 3px;
  position: absolute;
  z-index: 99999;
}

.MuiTab-wrapper {
  align-items: flex-start !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #0a2750 !important;
}

.MuiTabs-indicator {
  background-color: #021633 !important;
}

.submitted-files {
  background-color: rgba(246, 246, 246, 0.4) !important;
  color: #000 !important;
}

.submitted-files-icon {
  color: #000 !important;
  border-radius: 50% !important;
}

.submitted-file-container {
  overflow: auto;
  display: inline-table;
  position: relative;
}

.submitted-file-container div {
  float: left;
  margin: 5px;
}

.plus-menu {
  background-color: #F0F0F0;
  width: 100%;
  display: none;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 9;
  border: 1px solid #000;
  overflow: scroll;
}

.plus-menu li span {
  vertical-align: middle;
}

.plus-menu li {
  padding: 0px 10px;
  margin: 0;
  display: block;
  line-height: 40px;
  cursor: pointer;
}

.plus-menu li:hover {
  background-color: #e5e3e3;
}

div[data-field="availableMedia"] {
  position: relative;
  overflow: initial !important;
}

.submitted-file-container .MuiChip-label {
  padding-left: 0;
}

.MuiDataGrid-renderingZone {
  max-height: 500px !important;
}

.MuiDataGrid-root .MuiDataGrid-viewport {
  overflow: visible !important;
  max-height: auto !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderMoving {
  background-color: #fff !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.action-button {
  color: #000;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.MuiTab-wrapper div {
  text-transform: none !important;
}

.submitted-file-container .MuiChip-label {
  display: inline-block !important;
  height: 25px !important;
  line-height: 26px;
}

.carousel.carousel-slider .control-arrow, .carousel .control-arrow {
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer
}

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100)
}

.carousel.carousel-slider .control-arrow:before, .carousel .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: ""
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none
}

.carousel .control-prev.control-arrow {
  left: 0
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff
}

.carousel .control-next.control-arrow {
  right: 0
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff
}

.carousel-root {
  outline: none;
  width: 280px;
  margin: 0 auto;
}

.carousel {
  position: relative;
  width: 100%
}

.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.carousel img {
  width: 100%;
  display: inline-block;
}

.carousel .carousel {
  position: relative
}

.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px
}

.carousel .thumbs-wrapper {
  margin: 5px;
  overflow: hidden
}

.carousel .thumbs {
  -webkit-transition: all .15s ease-in;
  -moz-transition: all .15s ease-in;
  -ms-transition: all .15s ease-in;
  -o-transition: all .15s ease-in;
  transition: all .15s ease-in;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-top: 2px;
}

.carousel .thumb {
  -webkit-transition: border .15s ease-in;
  -moz-transition: border .15s ease-in;
  -ms-transition: border .15s ease-in;
  -o-transition: border .15s ease-in;
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
  height: 46px;
}

.carousel .thumb img {
  height: 40px;
  width: auto;
  margin: 0 auto;
  display: block;
}

.carousel .thumb:focus {
  border: 1px solid #ccc;
  outline: none
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 1px solid #333
}

.carousel .thumb img {
  vertical-align: top
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden
}

.carousel .control-arrow {
  top: 0;
  color: #000 !important;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px
}

.thumbs-wrapper .control-arrow.control-prev {
  margin-left: -20px !important;
  margin-top: -38px;
}

.thumbs-wrapper .control-arrow.control-next {
  margin-right: -20px !important;
  margin-top: -38px;
}

.thumbs-wrapper .control-arrow.control-prev:before {
  border-right: 8px solid #000;
}

.thumbs-wrapper .control-arrow.control-next:before {
  border-right: 8px solid #000;
  transform: rotate(180deg);
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, .2)
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height .15s ease-in;
  -moz-transition: height .15s ease-in;
  -ms-transition: height .15s ease-in;
  -o-transition: height .15s ease-in;
  transition: height .15s ease-in
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex
}

.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column
}

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%
}

.carousel .slider.animated {
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center
}

.carousel .slide img {
  width: auto;
  height: 200px;
  vertical-align: top;
  border: 0
}

.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0
}

.carousel .slide .legend {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: .25;
  -webkit-transition: opacity .35s ease-in-out;
  -moz-transition: opacity .35s ease-in-out;
  -ms-transition: opacity .35s ease-in-out;
  -o-transition: opacity .35s ease-in-out;
  transition: opacity .35s ease-in-out
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0
  }
}

@media (max-width: 960px) {
  div[data-name="topbar-with-padding"] .header-text {
    display: none;
  }
  .card-selection-container {
    padding: 30px !important;
  }
}

.carousel .control-dots .dot {
  -webkit-transition: opacity .25s ease-in;
  -moz-transition: opacity .25s ease-in;
  -ms-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100)
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .9);
  color: #fff
}

.carousel:hover .slide .legend {
  opacity: 1
}

.open-media {
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 2px 4px;
  background-color: #f5f5dce6;
  width: 30px;
  height: 26px;
}

.download-media {
  cursor: pointer;
  position: absolute;
  right: 30px !important;
  padding: 2px 4px;
  background-color: #f5f5dce6;
  width: 30px;
  height: 26px;
}

.open-media svg, .download-media svg {
  width: 22px;
  height: 22px;
}

.carousel.carousel-slider .control-arrow, .carousel .control-arrow {
  top: 34px !important
}

.layout2 {
  background-color: #fff !important;
}

.MuiFormLabel-root {
  margin-bottom: 20px;
  // font-weight: bold !important;
  color: #000 !important;
  // font-size: 1.25rem !important;

  &.MuiInputLabel-shrink {
    font-weight: bold !important;
  }
}

.MuiDialogContent-dividers {
  padding: 41px 41px !important;
}

.MuiDialogContent-root:first-child {
  padding-top: 43px !important;
  padding-bottom: 0px !important;
}

.MuiDialogContent-dividers {
  border-bottom: 0 !important;
}

#AssistBoxQueue {
  /*display: none !important;*/
}

#assistbox-queue-frame {
  position: relative !important;
}

.rdrDateDisplayWrapper {
  background-color: #fff !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDateDisplayWrapper {
  display: none !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #092A5C !important
}

div[data-name="topbar-with-padding"] > div {
  padding: 80px;
}

div[data-name="topbar-with-padding"] .header-text {
  color: #fff;
  font-size: 40px;
  text-align: center;
  padding-right: 60px !important;
}

.card-type-button {
  margin: 0 auto;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: rgb(222, 221, 221);
  display: flex;
}

.card-selection-container {
  padding: 120px;
}

.card-type-button img {
  padding: 25px;
  width: 60%;
  height: 60%;
  align-self: center;
  cursor: pointer;
}

.card-type-selection-label {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.web-sdk-footer {
  background-color: rgba(225, 225, 225, 0.9);
  border: 0;
  width: calc(100%);
  display: flex;

}

#assistbox-queue-frame {
  display: none !important;
}

.select-info {
  text-align: center;
  margin: 20px;
  font-size: 18px;
}

.footer-grid div {
  font-size: 16px;
  text-align: center;
  display: table;
}

.footer-grid div div {
  display: table-cell;
  vertical-align: middle;
}

#language-select svg {
  top: calc(50% - 13px);
}

#language-select-dropdown {
  margin-left: -7px;
}

.agent-select .MuiSelect-select {
  padding: 15px 50px 15px 15px
}

.alerts-border {
  animation: pulse-red 1s infinite;
}

.alerts-border-section {
  animation: pulse-red 1s infinite;
  animation-iteration-count: 10;
}

.alerts-border-scale {
  animation: pulse-red-scale 1s infinite;
}

@keyframes pulse-red {
  0% {
    transform-origin: center center;
    box-shadow: 0 0 0 0 rgba(255, 158, 67, 0.7);
  }

  70% {
    transform-origin: center center;
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform-origin: center center;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red-scale {
  0% {
    transform: scale(1.10);
    transform-origin: center center;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    transform-origin: center center;
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(1.10);
    transform-origin: center center;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.video_call_container {
  p, span {
    font-size: 12px;
  }
  div {
    font-size: 13px;
  }
  h6 {
    font-size: 14px;
  }
  h3 {
    font-size: 20px;
  }
  .MuiDataGrid-cell[data-field="control"] {
    max-width: 320px !important;
    min-width: 320px !important;
  }
  .MuiDataGrid-dataContainer.data-container, .MuiDataGrid-renderingZone {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .carousel .slide img {
    height: 145px;
  }
  .zone-5 button:disabled {
    background-color: #646B6C !important;
  }
}

.image-compare .MuiDialogContent-root:first-child {
  padding-top: 20px !important;
}

.compareMediaButton {
  background-color: #B497FF !important;
  margin: 0 auto;
  display: block;
  color: #fff !important;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  top: -50px;
}

.nav-title {
  display: flex;
  align-items: center;
  padding: 10px 15px;

  h4 {
    color: #fff !important;
    font-size: 16px;
    margin: 0;
    margin-left: 10px;
    margin-top: 2px;
  }
}

.nav-item .item-text {
  color: rgba(#fff, 0.7) !important;
  font-family: 'Roboto', sans-serif;
}

[class*="MuiAccordion-root-"] {
  box-shadow: none !important;
  background-color: transparent !important;

  &:before {
    opacity: 0 !important;
  }

  &.Mui-expanded {
    margin: 0 !important;
  }
}

[class*="MuiAccordionSummary-root-"].Mui-expanded {
  min-height: 48px !important;
}

[class*="MuiAccordionSummary-content-"].Mui-expanded {
  margin: 12px 0 !important;
}

.text-start {
  text-align: left;
}
