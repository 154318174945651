.bg-circle-primary {
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-circle-secondary {
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-circle-warn {
  background-size: cover;
  background-repeat: no-repeat;
}
