.list {
  padding-bottom: 50px;

  .list-view {
    .list__card {
      .project-image {
        height: 75px;
        width: 100px;
      }

      .card__button-group {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        right: 0;
      }

      &:hover {
        .card__button-group {
          display: flex;
        }
      }
    }
  }

  .grid-view {
    .grid__card {
      position: relative;

      &:hover {
        .grid__card-top {
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.54);
            z-index: 1;

            @include keyframeMaker(fade-in) {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            animation: fade-in 250ms #{bezier()};
          }

          .grid__card-overlay {
            display: block;
          }
        }

        .grid__card-bottom {
          .email {
            display: block;
          }
          .date {
            display: none;
          }
        }
      }

      .grid__card-top {
        position: relative;

        .grid__card-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: none;
          z-index: 2;

          & > div:nth-child(2) {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: -1;
          }
        }

        img {
          // max-height: 200px;
        }
      }
      .grid__card-bottom {
        .email {
          display: none;
        }
      }
    }
  }
}

.MuiPagination-ul .Mui-selected {
  background-color: #fff !important;
  border: 1px solid #007bff;
  color: #007bff!important;
}

#assistbox-video-frame {
  height: 100%;
  border: 0;
}

#AssistBoxMeeting {
  height: calc(100% - 136px);
}

.nav_container {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.nav_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(15rem,1fr));
  grid-gap: 2rem;
  max-width: 900px;
  margin: 0 auto;
  justify-items: center
}

.nav_card {
  text-align: center;
  cursor: pointer;

  &_inner {
    background-color: #092A5C;
    border-radius: 20px;
    width: fit-content;
    padding: 10px 20px;
    box-shadow: 0 15px 30px rgb(0 0 0 / 15%);
    transition: transform .3s;
    margin: 0 auto;

    &:hover {
      transform: translateY(-15px) scale(1.03);
    }
  }

  &_icon {
    color: #fff;
    font-size: 128px !important;
  }

  &_text {
    margin-top: 10px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
  }
}

.section_wrapper {
  padding: 25px 30px;
}

.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
}

.grid_wrapper {
  height: calc(100vh - 180px);
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.select-field {
  .MuiSelect-root {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
}

.bordered-section {
  border: 2px solid #092A5C;
  border-radius: 10px;
  padding: 15px 20px;
  margin-left: -20px;
  width: calc(100% + 20px) !important;

  .MuiFormControl-root.MuiTextField-root, .select-field {
    margin-left: 4px;
  }
}

.padded-section {
  border: 1px solid #B8C0C1;
  border-radius: 5px;
  padding: 10px 20px;
  overflow-y: auto;
}

.middle-section {
  padding: 0 30px;
  display: flex;
  flex-direction: column;

  .padded-section:first-of-type {
    margin-bottom: 20px;
  }

  .padded-section:last-of-type {
    flex: 1;
  }
}

.media-section {
  flex: 1;
}

.image-item {
  width: auto;
  height: 135px;
  margin: 0 auto;
  display: block;
}

.digit-group {
	input {
		width: 30px;
		height: 50px;
		background-color: lighten(red, 5%);
		border: none;
		line-height: 50px;
		text-align: center;
		font-size: 24px;
		font-family: 'Raleway', sans-serif;
		font-weight: 200;
		color: white;
		margin: 0 2px;
	}
}
