.signup {
  background: #1A2038;


  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;

    img {
      width: 200px;
    }
  }
}



.otp {
  padding: 36px 60px 36px 36px !important;
  width: fit-content;

  h1 {
    margin-bottom: 20px;
  }

  &-input {
    width: 40px !important;
    height: 40px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3);

    &:not(:first-of-type) {
      margin-left: 8px;
    }

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  &-buttons {
    margin-top: 20px;

    & button {
      width: calc(50% - 8px);

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}